import { MaterialArrowBackSvg } from '@canalplus/dive';
import { Binder, KEY_BACK, KEY_UP, useActiveLayer, useKeyCatcher, useStore } from '@canalplus/one-navigation';
import { MiniControls } from '@canalplus/oneplayer-shared-components';
import '@canalplus/oneplayer-shared-components/dist/css/index.css'; // Global styles of oneplayer-shared-components
import classNames from 'classnames';
import { useCallback, useDeferredValue, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUnmount } from 'usehooks-ts';
import type { PlayerPlatform } from '../../../../constants/playerPlatforms';
import { LAYER_EMBEDDED_VIDEO_FULL_FRAME, LAYER_IMMERSIVE } from '../../../../helpers/oneNavigation/layers';
import I18n from '../../../../lang';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import { resetValues, setPlayerInstance } from '../../context/actions';
import { useIsVideoReadyToPlay } from '../../context/hooks/useIsVideoReadyToPlay';
import { useSetIsFullFrame } from '../../context/hooks/useSetIsFullFrame';
import { useVideoDispatch } from '../../context/hooks/useVideoDispatch';
import { useVideoState } from '../../context/hooks/useVideoState';
import { useGetPlayerCredentials } from '../../hooks/useGetPlayerCredentials';
import styles from './PlayerContainer.css';
import { useGetPlayerInstance } from './useGetPlayerInstance';
import { useLoadOnePlayerMinimalScript } from './useLoadOnePlayerMinimalScript';

// Warning: to avoid rerendering side effects, do not update the props to accept objects
export type PlayerContainerProps = {
  /**
   * If true, main div is scaled up to hide the back bars
   */
  areBlackBarsHidden?: boolean;
  /**
   * The contentId or the url (if no DRM) of the content needed by OnePlayer
   */
  contentIdOrUrl: string;
  /**
   * The format of the video
   */
  format: PlayerPlatform | 'manual-hls';
  /**
   * If true, displays player controls
   */
  hasControls?: boolean;
  /**
   * If true, contentIdOrUrl is used as a contentID
   */
  isEncrypted?: boolean;
  /**
   * If true and `hasControls` enabled, displays exit button
   */
  hasExitButton?: boolean;
  /**
   * If true, display on TV the full frame indicator
   */
  hasFullFrameIndicator?: boolean;
  /**
   * If true, displays subtitles
   */
  hasSubtitles?: boolean;
  /**
   * If true, the video will indefinitely loop
   */
  isLoop?: boolean;
  /**
   * If the value is changing, the player will be updated with the new one
   */
  isMuted: boolean;
  /**
   * If true, player is paused
   */
  isPaused?: boolean;
  /**
   * To be performed when the video is ending in the Player
   * E.g. used to launch the next video in promotion strates
   */
  onNaturalEnding?: () => void;
  /**
   * To be performed when the sound value change in the Player
   * E.g. used to dispatch to the store to save the value of sound for others trailers
   */
  onSoundChange?: (value: boolean) => void;
};

function PlayerContainer({
  areBlackBarsHidden = false,
  contentIdOrUrl,
  format,
  hasControls = false,
  hasExitButton = true,
  hasFullFrameIndicator = false,
  hasSubtitles = false,
  isEncrypted = false,
  isLoop = false,
  isMuted,
  isPaused = false,
  onNaturalEnding,
  onSoundChange,
}: PlayerContainerProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const { isFullFrame, playerInstance } = useVideoState();
  const deferredIsMuted = useDeferredValue(isMuted);
  const deferredContentIdOrUrl = useDeferredValue(contentIdOrUrl);
  const videoDispatch = useVideoDispatch();
  const isVideoReadyToPlay = useIsVideoReadyToPlay();
  const store = useStore(); // OneNavigation store
  const setIsFullFrame = useSetIsFullFrame();
  const isTvDevice = useSelector(displayTVModeSelector);

  // To get OnePlayer parameters
  const credentials = useGetPlayerCredentials();
  const isPassTokenValid = !!credentials.passToken && credentials.passToken.length > 0; // OnePlayer handle passToken renewal

  const { getPlayerInstance, getPlayerConfig, playerContainerRef, subtitlesContainerRef } = useGetPlayerInstance({
    contentIdOrUrl,
    format,
    hasSubtitles,
    isEncrypted,
    isLoop,
    isMuted,
    onNaturalEnding,
    onSoundChange,
  });

  // OnePlayerMinimal instancing
  const { isLoaded: isOnePlayerMinimalScriptLoaded } = useLoadOnePlayerMinimalScript();

  const instantiateVideo = useCallback(() => {
    videoDispatch(setPlayerInstance(getPlayerInstance()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // wait until minimal player is loaded and check if the passtoken is valid
    if (isOnePlayerMinimalScriptLoaded && isPassTokenValid) {
      instantiateVideo();
    }
  }, [isOnePlayerMinimalScriptLoaded, isPassTokenValid, instantiateVideo]);

  const removePlayer = () => {
    if (!playerInstance) {
      console.warn('[PlayerContainer]: memory leak ! Unable to destroy instance of onePlayerMinimal');
    }

    console.info('[Video]: OnePlayerMinimal destroy instance');
    playerInstance?.destroy();
    videoDispatch(resetValues()); // To reset the context values
  };

  // Remove player on unmount
  useUnmount(() => {
    removePlayer();
  });

  // When the contentIdOrUrl changes, start the new content
  useEffect(() => {
    if (playerInstance && deferredContentIdOrUrl !== contentIdOrUrl) {
      // eslint-disable-next-line no-console
      console.info('[Video]: OnePlayerMinimal start');

      playerInstance.destroy({ shouldDestroyCorePlayer: false });
      playerInstance.start({ config: getPlayerConfig({ autoplay: true }) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentIdOrUrl]);

  // Handle closing full frame by escape
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        setIsFullFrame(false);
      }
    },
    [setIsFullFrame]
  );

  useEffect(() => {
    if (isFullFrame) {
      document.body.addEventListener('keydown', handleKeydown);
    } else {
      document.body.removeEventListener('keydown', handleKeydown);
    }

    return () => {
      document.body.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown, isFullFrame]);

  // Handle Full Frame opening and closing by One Navigation
  useActiveLayer(LAYER_EMBEDDED_VIDEO_FULL_FRAME, isFullFrame);
  useKeyCatcher(
    KEY_BACK,
    () => {
      if (isFullFrame) {
        setIsFullFrame(false);
      }
    },
    LAYER_EMBEDDED_VIDEO_FULL_FRAME
  );
  useKeyCatcher(
    KEY_UP,
    () => {
      // Open Full Frame only when the focus is on the first element of the active layer of OneNavigation...
      const firstInteractiveElement = store.layers[store.activeLayer].binders[0]?.getElements()[0];
      // ... and the video is ready to be played (player is ready and animations are done)
      if (hasFullFrameIndicator && isVideoReadyToPlay && !isFullFrame && store.current === firstInteractiveElement) {
        setIsFullFrame(true);
      }
    },
    LAYER_IMMERSIVE
  );

  // Impact the player with the props isMuted only if the value is changing
  useEffect(() => {
    if (!playerInstance || deferredIsMuted === isMuted) {
      return;
    }
    if (isMuted) {
      playerInstance.mute();
    } else {
      playerInstance.unMute();
    }
  }, [deferredIsMuted, isMuted, playerInstance]);

  // Impact the player with the props isPaused
  useEffect(() => {
    if (!playerInstance) {
      return;
    }
    if (isPaused) {
      playerInstance.pause();
    } else {
      playerInstance.play();
    }
  }, [isPaused, playerInstance]);

  return (
    <div
      className={classNames(styles.container, {
        [styles['container--fullscreen']]: isFullFrame,
      })}
    >
      {hasFullFrameIndicator && isVideoReadyToPlay && !isFullFrame && (
        <div className={styles.indicator}>
          <MaterialArrowBackSvg />
          <span>{t('Video.fullscreen')}</span>
        </div>
      )}
      {hasSubtitles && <div ref={subtitlesContainerRef} />}
      <div
        className={classNames(styles.player, {
          [styles['player--scale-up']]: areBlackBarsHidden && !isFullFrame,
        })}
        ref={playerContainerRef}
      />
      {(isFullFrame || hasControls) && (
        <Binder enabled={isFullFrame} className={styles.controls}>
          <MiniControls
            forceFocusOnFullFrame
            isFullFrame={isFullFrame}
            isTv={isTvDevice}
            onExit={() => {
              setIsFullFrame(false);
            }}
            onFullFrame={() => {
              setIsFullFrame(!isFullFrame);
            }}
            shouldShowExitButton={hasExitButton}
          />
        </Binder>
      )}
    </div>
  );
}

export default PlayerContainer;
