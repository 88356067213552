import { ApiV2HapiGetMediaUrl } from '@dce-front/hodor-types';
import { ApiV2CoverTrailer } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import classNames from 'classnames';
import { useDeferredValue, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PlayerPlatform } from '../../constants/playerPlatforms';
import { useIntersectionObserver } from '../../helpers/hooks/useIntersectionObserver';
import { useQueryTemplate } from '../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import type { FromProp } from '../../server/modules/fetchWithQuery/types';
import { FetchRequestTypes } from '../../services/types';
import { isPlayerOpenSelector } from '../../store/slices/player-selectors';
import { useIsOnCurrentRoutingContext } from '../Page/RoutingContext';
import PlayerContainer from './components/PlayerContainer/PlayerContainer';
import { setIsWaitingTimeBeforePlayingOver } from './context/actions';
import { useIsVideoReadyToPlay } from './context/hooks/useIsVideoReadyToPlay';
import { useToggleIsPaused } from './context/hooks/useToggleIsPaused';
import { useVideoDispatch } from './context/hooks/useVideoDispatch';
import { useVideoState } from './context/hooks/useVideoState';
import { VideoContent } from './types';
import styles from './Video.css';

const getVideoFormat = (videoContent: VideoContent): PlayerPlatform | 'manual-hls' => {
  if (videoContent.encryption === 'encrypted') {
    return PlayerPlatform.Hapi;
  }

  // TODO[PromotionStrates]: remove endsWith, in waiting of an other way to determine the video format
  // .m3u8 = hls
  if (videoContent.format === 'hls' || videoContent.url.endsWith('.m3u8')) {
    return 'manual-hls';
  }

  return PlayerPlatform.Directfile;
};

const getVideoContent = ({
  dataMedia,
  videoContent,
}: {
  dataMedia?: ApiV2HapiGetMediaUrl;
  videoContent?: VideoContent;
}): VideoContent | undefined => {
  if (videoContent) {
    return videoContent;
  }

  const contentID = dataMedia?.detail.informations.contentID;
  const encryption = dataMedia?.detail.informations.playsets.available?.[0].encryption;
  const url =
    // @ts-expect-error: videoUrl is received but videoURL is not declared in type
    dataMedia?.detail.informations.playsets.available?.[0].videoUrl ||
    dataMedia?.detail.informations.playsets.available?.[0].videoURL;

  if (encryption === 'clear' && url) {
    return {
      encryption: 'clear',
      url,
    };
  }

  if ((encryption === 'encrypted' || !encryption) && contentID) {
    return {
      encryption: 'encrypted',
      contentID,
    };
  }

  return undefined;
};

export type VideoProps = {
  /**
   * If true, main div is scaled up to hide the back bars
   */
  areBlackBarsHidden?: boolean;
  /**
   * Element will be displayed before and after the video
   */
  cover: JSX.Element;
  /**
   * Destroy the video when the main player is open
   * default: false
   */
  destroyOnIsMainPlayerOpen?: boolean;
  /**
   * If true, displays player controls
   */
  hasControls?: boolean;
  /**
   * If true and `hasControls` enabled, displays exit button
   */
  hasExitButton?: boolean;
  /**
   * If true, display on TV the full frame indicator
   */
  hasFullFrameIndicator?: boolean;
  /**
   * The minimum visible threshold of the element necessary to play the video
   */
  intersectionThreshold?: number;
  /**
   * If true, the video will indefinitely loop
   */
  isLoop?: boolean;
  /**
   * The sound value given to the player
   * E.g. can be updated after player instantiation to synchronize all displayed trailers
   */
  isMuted: boolean;
  /**
   * If false, the player does not start or is paused
   */
  isVisible?: boolean;
  /**
   * To be performed when the video is ending in the Player
   * E.g. used to launch the next video in promotion strates
   */
  onNaturalEnding?: () => void;
  /**
   * To be performed at the same time as the sound value change in the Player
   * E.g. used to dispatch to the store to save the value of sound for others trailers
   */
  onSoundChange?: (boolean) => void;
  /**
   * Minimal time to display covers
   */
  timeBetweenCoversAndPlayer?: number;
  /**
   * Url to fetch the contentID for onePlayer
   */
  urlMedias?: ApiV2CoverTrailer['URLMedias'];
  /**
   * If false, the player will not be destroyed at the end of the video
   */
  shouldDestroyPlayerAtTheEnd?: boolean;
  /**
   * Fetch request type for url medias request
   */
  template?: FetchRequestTypes;
  /**
   * To not fetch data by calling the urlMedias, give the content (direct url or contentID) and the encryption status to retrieved the video content
   */
  videoContent?: VideoContent;
} & FromProp;

function Video({
  areBlackBarsHidden = false,
  cover,
  destroyOnIsMainPlayerOpen = false,
  from,
  hasControls = false,
  hasExitButton = true,
  hasFullFrameIndicator = false,
  intersectionThreshold = 0.3,
  isLoop = false,
  isMuted,
  isVisible = true,
  onNaturalEnding,
  onSoundChange,
  timeBetweenCoversAndPlayer = 2000,
  shouldDestroyPlayerAtTheEnd = true,
  template = FetchRequestTypes.MediaUrl,
  urlMedias,
  videoContent,
}: VideoProps): JSX.Element {
  const { isFullFrame, isEnded, isPaused, isWaitingTimeBeforePlayingOver } = useVideoState();
  const isVideoReadyToPlay = useIsVideoReadyToPlay();
  const videoDispatch = useVideoDispatch();

  // To manage the mount and unmount of PlayerContainer (related to the new and destroy of minimalPlayer)
  const deferredUrlMedias = useDeferredValue(urlMedias);
  const [showPlayer, setShowPlayer] = useState(true);

  // To manage behavior when an other foreground element is displayed
  const isMainPlayerOpen = useSelector(isPlayerOpenSelector);
  const isOnCurrentRoutingContext = useIsOnCurrentRoutingContext();
  const { pause: pauseByImmersive, play: playByImmersive } = useToggleIsPaused();

  // To manage scroll behavior
  const { pause: pauseByVisibility, play: playByVisibility } = useToggleIsPaused();
  const [isIntersectionObserverReady, setIsIntersectionObserverReady] = useState(false);
  const { refCallback: rootRef, entry } = useIntersectionObserver({
    threshold: intersectionThreshold,
    isEnabled: isIntersectionObserverReady && !isFullFrame,
  });
  const isIntersecting = entry && entry.intersectionRatio > intersectionThreshold; // If intersectionRatio is 0.3, at least 30% (0.3) of the rootRef has to be in the viewport
  const isVisibleByPropsAndByScroll = isVisible && (isIntersecting || isFullFrame);

  // Because the IntersectionObserver entry can have all values set to default
  // e.g. when a detail is opened after a page change
  useEffect(() => {
    if (!isIntersecting) {
      const timer = setTimeout(() => {
        setIsIntersectionObserverReady(true);
      }, 0);
      return () => clearTimeout(timer);
    }
    return;
  }, [isIntersecting]);

  // Fetch data for the video
  const [{ data: dataMedia }] = useQueryTemplate<ApiV2HapiGetMediaUrl>(
    urlMedias,
    {
      template,
      from,
    },
    {
      enabled: !!urlMedias,
    }
  );
  const content = getVideoContent({ dataMedia, videoContent });

  // Player must be render immediately to loaded the video
  // Player must be displayed (not render) after at least timeBeforePlaying
  // Animation before playing
  useEffect(() => {
    if (!isWaitingTimeBeforePlayingOver && isVisibleByPropsAndByScroll) {
      const timer = setTimeout(
        () => videoDispatch(setIsWaitingTimeBeforePlayingOver(true)),
        timeBetweenCoversAndPlayer
      );
      return () => clearTimeout(timer);
    }
    return;
  }, [videoDispatch, isVisibleByPropsAndByScroll, isWaitingTimeBeforePlayingOver, timeBetweenCoversAndPlayer]);

  // When natural ended: Player must be unmount after animation
  useEffect(() => {
    if (isEnded) {
      const timer = setTimeout(() => {
        if (shouldDestroyPlayerAtTheEnd) {
          setShowPlayer(false);
        }
      }, timeBetweenCoversAndPlayer);
      return () => clearTimeout(timer);
    }
    return;
  }, [isEnded, shouldDestroyPlayerAtTheEnd, timeBetweenCoversAndPlayer]);

  // When the main player is opening
  useEffect(() => {
    if (isMainPlayerOpen) {
      if (destroyOnIsMainPlayerOpen) {
        setShowPlayer(false); // Do not display the video again for this content
      } else {
        pauseByImmersive();
      }
    } else {
      playByImmersive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMainPlayerOpen, destroyOnIsMainPlayerOpen, videoDispatch]);

  // When the immersive is opening or is closing
  useEffect(() => {
    if (isOnCurrentRoutingContext) {
      playByImmersive();
    } else {
      pauseByImmersive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnCurrentRoutingContext]);

  // When content is changing (eg: when navigation, like back, in the immersive)
  // TODO: should be related to urlMedias AND videoContent
  // TODO: should be removed when the minimalPlayer instance can load an other content
  useEffect(() => {
    if (deferredUrlMedias !== urlMedias) {
      setShowPlayer(false); // To unmount PlayerContainer

      const timer = setTimeout(() => {
        setShowPlayer(true); // To mount PlayerContainer to instantiate minimalPlayer with the new values
      }, 500);
      return () => clearTimeout(timer);
    }
    return;
    // Should activate this useEffect only when urlMedias has been changed to compare it with deferredUrlMedias
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlMedias]);

  // When visibility is changing
  useEffect(() => {
    if (!isVisibleByPropsAndByScroll) {
      pauseByVisibility();
    } else {
      playByVisibility();
    }
  }, [isVisibleByPropsAndByScroll, pauseByVisibility, playByVisibility]);

  return (
    <div className={classNames(styles.video)} ref={rootRef}>
      {content && showPlayer && (
        <div className={classNames(styles.videoItem)} aria-hidden={!isVideoReadyToPlay}>
          <PlayerContainer
            areBlackBarsHidden={areBlackBarsHidden}
            contentIdOrUrl={content.encryption === 'encrypted' ? content.contentID : content.url}
            format={getVideoFormat(content)}
            hasControls={hasControls}
            hasExitButton={hasExitButton}
            hasFullFrameIndicator={hasFullFrameIndicator}
            isEncrypted={content.encryption === 'encrypted'}
            isLoop={isLoop}
            isMuted={isMuted}
            isPaused={!isVisibleByPropsAndByScroll || !isVideoReadyToPlay || isPaused}
            onNaturalEnding={onNaturalEnding}
            onSoundChange={onSoundChange}
          />
        </div>
      )}
      <div
        className={classNames(styles.videoItem, {
          [styles['videoItem--fadeOut']]: isVideoReadyToPlay,
          [styles['videoItem--fadeIn']]: isEnded,
        })}
        aria-hidden={isVideoReadyToPlay && showPlayer}
      >
        {cover}
      </div>
    </div>
  );
}

export default Video;
